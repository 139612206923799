import Service from "../Service"

const resource = "assemblystatic/"

export default {

    save(cmp, requestID){
        return Service.post(resource + "save", cmp,{
            params: {requestID: requestID},
        });
    },
    pagination(dtr, requestID){
        return Service.post(resource + "pagination", dtr,{
            params:{requestID: requestID},
        });
    },
    get(fas, requestID) {
        return Service.post(resource + "getstatic", fas, {
          params: { requestID: requestID },
        });
    },
    GetQr(fas, requestID) {
        return Service.post(resource + "GetQr", fas, {
          params: { requestID: requestID },
        });
    },
    
}