import Service from "../Service";
import Vue from "vue";
const resource = "FrzChamberCustomerBrand/";

export default {
    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },
    listdetail(prd, requestID) {
        return Service.post(resource + "list", prd, {
            params: { requestID: requestID },
        });
    },
    listResponsible(prd, requestID) {
        return Service.post(resource + "listResponsible", {}, {
            params: { requestID: requestID },
        });
    },

    // getUrlListClientBrans() {
    //     return Vue.prototype.$http.defaults.baseURL + "/FrzChamberCustomerBrand/listclientbrans";
    // },
    getUrlListClientBrans() {
        return Vue.prototype.$http.defaults.baseURL + "/FrzChamberCustomerBrand/listclientbrans";
    },
    getUrlListClientByBrans(cumID, requestID) {
        return Service.post(
            this.getUrlListClientBrans(), {}, {
                params: { cumID: cumID, requestID: requestID },
            }
        );
    },
    
}; 