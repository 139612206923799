<template>
    <div v-if="selected.length > 0">
        <s-toolbar dense dark @save="saveContainer()" color="#7b7b7b" save close @close="close()" label="Mover a contenedor"></s-toolbar>
        <v-card>
            <v-card-title class="primary--text">Datos</v-card-title>
                <v-card-text>
                    <v-container grid-list-xs class="ma-0 pa-0">
                        <v-row>
                            <v-col cols="2" md="2" lg="2" class="s-col-form">
                                {{ 'ID: ' +  selected[0].FasID}}
                            </v-col>
                            <v-col >
                                {{ selected[0].FasDescription }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col md="6" lg="6" class="s-col-form">
                                <s-select :items="LocationArea"
                                    label="Area"
                                    item-value="FpcArea"
                                    v-model=FpcArea
                                    item-text="FpcAreaName"
                                    @input="initialize()"
                                    >
                                </s-select>
                            </v-col>
                            <v-col class="s-col-form">
                                <s-text label="Temperatura" type="Number" v-model="IncomeTemp">

                                </s-text>
                            </v-col>
                            <v-col cols="12" >
                               <s-select 
                                    :items="itemscontenedores"
                                    item-value="FpcID"
                                    item-text="FpcDescription"
                                    label="Seleccionar contenedor"
                                    return-object
                                    autocomplete
                                    v-model="objContainer"
                                    :slotTmp="true">
                                    <template v-slot:item="data">
                                        {{ data.item.FpcDescription }}
                                        <v-chip 
                                            small 
                                            class="ma-2"
                                            variant="outlined"
                                            :color="data.item.inColor"
											><b>{{
												data.item.inCarUsage
											}}</b>

                                        </v-chip>
                                    </template>
                                </s-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
        </v-card>
    </div>
</template>

<script>
import SSelect from '../../../components/Utils/SSelect.vue';
import _sProcessContainers from '@/services/FrozenProduction/FrzCtnProcessContainers.js';

    export default {
  components: { SSelect },
        props:
        {
            value:{
                required: false,
                default: -1,
            },       
            selected: {
                required: false,
                default: Array,
            },    
        },
        data () {
            return {
                carrito: this.selected,
                itemscontenedores: [],
                objContainer: {},
                // FasID:0
                LocationArea: [
                    { FpcArea: 1, FpcAreaName: 'Acondicionado'},
                    { FpcArea: 2, FpcAreaName: 'Empaque'}
                ],
                FpcArea: 1,
                IncomeTemp: 0
            };
        },
        created(){        
             
            this.initialize();
        },
        methods:
        {
            close()
            {                
                this.$emit("closedialog");
            },
            initialize()
            {             
                _sProcessContainers.list({FpcArea: this.FpcArea}, this.$fun.getUserID()).then(r => {
                    if(r.status == 200)
                    {
                        r.data.forEach(element => {
                            element.ParihuelaUsagePorcentaje = (100 * element.inCarUsage) /  parseInt(element.FpcCapacity)
                            element.ConatinerAvailableUnit = parseInt(element.FpcCapacity) - parseInt(element.inCarUsage);

                            if(element.ParihuelaUsagePorcentaje >= 100){
								element.inColor = 'error'
							}

							//Tunnel por llenarse
							if(element.ParihuelaUsagePorcentaje >= 80 && element.ParihuelaUsagePorcentaje < 100){
								element.inColor = 'warning'
							}

							if(element.ParihuelaUsagePorcentaje > 35 && element.ParihuelaUsagePorcentaje <= 79){
								element.inColor = 'info'
							}

							if(element.ParihuelaUsagePorcentaje <= 35){
								element.inColor = 'success'
							}
                        });
                        this.itemscontenedores = r.data;                        
                    }
                });
            },
            saveContainer(){
                let item = {}

                if(this.objContainer.ConatinerAvailableUnit == 0){
                    this.$fun.alert("Error, contenedor se encuentra lleno. Por favor verifique", "warning")
                    return;
                }
                console.log(this.objContainer);
                if(this.objContainer.FpcID== undefined)
                {
                    this.$fun.alert("Error, seleccione un contenedor", "warning")
                    return;
                }

                item.FasID = this.selected[0].FasID;
                item.FpcID = this.objContainer.FpcID;
                item.IncomeTemp = this.IncomeTemp;
                item.SecStatus = 1
                item.UsrCreateID = this.$fun.getUserID();
                item.UsrUpdateID = this.$fun.getUserID();
                
                _sProcessContainers.savecarcontainer(item, this.$fun.getUserID())
                .then(resp => {
                    if(resp.status = 200)
                    {
                        this.$fun.alert("Registrado correctamente", "success");
                        this.$emit("save");
                        this.close();
                    }
                });
                
            }
        }
    }
</script>