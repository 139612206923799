<template>
    <div style="padding-top:-2px;margin-top:-2px;">
        <v-label>
            <b>{{ label }}</b>
        </v-label>
        <s-select-generic
            autocomplete 
            class="text"
            itemvalue="CbdID"
            :disabled="disabled"
            :value="value"
            id="CbdID" itemtext="CbdBrans"
            :return-object="returnObject"
            @input="input($event)"
            :config="config"
            :full="full"
            :text="text"
            :clearable="clearable"
            :noDefault="noDefault"
            :readonly="readonly"
            >
        </s-select-generic>
    </div>
</template>

<script>
    import _sFrzChamberCustomerBrandService from "@/services/FrozenProduction/FrzChamberCustomerBrandService.js";

    export default {
        components: {},
        props: 
        {
            disabled: {
                type: Boolean,
                default: false,
            },
            clearable: {
                type: Boolean,
                default: false,
            },
            value: null,
            text: { type: String, default: "" },
            label: {
                type: String,
                default: "",
            },
            returnObject: {
                type: Boolean,
                default: false,
            },
            noDefault: {
                type: Boolean,
                default: false,
            },    
            full: {
                type: Boolean,
                default: false,
            },
            cumID: {
                type: Number,
                default: 0,
            },
            readonly: {
				type: Boolean,
				default: false,
			},
        },
        watch: {
            value(value) {
                this.item = value;
            },
            cumID(){
                this.config.params.cumID = 
                    this.cumID == null ? 0 : this.cumID;
            },
        },
        methods:{
            input(val){
                this.$emit("input", val);
            }
        },
        data: () => ({
            item: 0,
            val: "",
            items: [],
            config: {},
        }),
        created(){
            
            this.config = {
            url: _sFrzChamberCustomerBrandService.getUrlListClientBrans(),
            title: this.label,
            params: {  
                search: "", 
                requestID: this.$fun.getUserID(), 
                cumID: this.cumID == null ? 0: this.cumID
                }
            };
         
        },
    };
</script>